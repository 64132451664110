'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { getCaseStudies } from '@vendure/marketing/data-access-storyblok'
import type {
  CaseStudyGridStoryblok,
  CaseStudyStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import { CaseStudyCard, CaseStudyGridFilter } from '@vendure/shared/ui'
import { useEffect, useMemo, useState } from 'react'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface CaseStudyGridWithCaseStudies extends CaseStudyGridStoryblok {
  caseStudies?: Array<StoryblokStory<CaseStudyStoryblok>>
}

interface ICaseStudyGrid {
  blok: CaseStudyGridWithCaseStudies
  containerBackgroundColor?: any
}

function extractAllValuesOfField(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
  field: string,
) {
  const values = []
  for (const caseStudy of caseStudies) {
    values.push(caseStudy.content[field])
  }

  const processed: string[] = values.reduce(
    (accumulator: string[], currentValue: string) => {
      if (currentValue.includes(',')) {
        const split = currentValue.split(',')
        return [...accumulator, ...split]
      }

      return [...accumulator, currentValue]
    },
    [],
  )

  const set = new Set(processed)

  return [...set]
}

function extractAllLocations(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
) {
  return extractAllValuesOfField(caseStudies, 'location')
}

function extractAllCategories(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
) {
  return extractAllValuesOfField(caseStudies, 'category')
}

export function CaseStudyGrid({
  blok,
  containerBackgroundColor,
}: ICaseStudyGrid) {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [caseStudies, setCaseStudies] = useState<
    Array<StoryblokStory<CaseStudyStoryblok>>
  >([])
  const [allCaseStudies, setAllCaseStudies] = useState<
    Array<StoryblokStory<CaseStudyStoryblok>>
  >([])

  const locations = useMemo(
    () => extractAllLocations(allCaseStudies),
    [allCaseStudies],
  )
  const categories = useMemo(
    () => extractAllCategories(allCaseStudies),
    [allCaseStudies],
  )

  const loadCaseStudies = async () => {
    const caseStudies = await getCaseStudies(
      'en',
      selectedCategories,
      selectedLocations,
    )
    setCaseStudies(caseStudies)
  }

  useEffect(() => {
    const load = async () => {
      const allCaseStudies = await getCaseStudies('en', [], [])
      setCaseStudies(allCaseStudies)
      setAllCaseStudies(allCaseStudies)
    }
    load()
  }, [])

  function onFilterChange(
    selectedCategories: string[],
    selectedLocations: string[],
  ) {
    setSelectedCategories([...selectedCategories])
    setSelectedLocations([...selectedLocations])
    loadCaseStudies()
  }

  return (
    <div {...storyblokEditable(blok)}>
      <CaseStudyGridFilter
        selectedCategories={selectedCategories}
        selectedLocations={selectedLocations}
        onFilterChange={onFilterChange}
        categoryOptions={categories}
        locationOptions={locations}
      />
      <div className="mt-5 grid  gap-6 sm:grid-cols-1 md:gap-10 lg:grid-cols-3">
        {caseStudies.map((item) => {
          return (
            <CaseStudyCard
              key={item.uuid}
              // @ts-ignore
              caseStudy={item}
            />
          )
        })}
      </div>
    </div>
  )
}
