import {
  StoryblokStory as StoryblokStoryComponent,
  type ISbStoryData,
} from '@storyblok/react/rsc'
import type {
  ConfigStoryblok,
  PageStoryblok,
} from '@vendure/marketing/util-storyblok-types'
import { StoryProvider } from '@vendure/shared/ui'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface StoryProps {
  story?: StoryblokStory<PageStoryblok | unknown> | ISbStoryData<any> | null
  config?: StoryblokStory<ConfigStoryblok>
  breadcrumbs?: Array<StoryblokStory<PageStoryblok | any>>
}

export function Story({ story }: StoryProps) {
  return (
    <StoryProvider story={story as StoryblokStory<unknown>}>
      <StoryblokStoryComponent story={story} />
    </StoryProvider>
  )
}
